import request from "@/utils/request";

export default {
  // 保存
  mobileanyPhotosave(data: any) {
    return request({
      url: "/project/projectAnyPhoto/save",
      method: "post",
      data: data,
    });
  },
  // 详情
  mobilesourceCheckgetById(id: any) {
    return request({
      url: "/project/projectAnyPhoto/getById/" + id,
      method: "get",
    });
  },
  // 删除
  mobilesourceCheckremove(id: any) {
    return request({
      url: "/project/projectAnyPhoto/remove/" + id,
      method: "get",
    });
  },
  // 选择问题处理人
  mobileanyPhotoallocateUser(data: any) {
    return request({
      url: "/project/projectAnyPhoto/allocateUser",
      method: "post",
      data: data,
    });
  },
  // 全部问题
  mobileanyPhotopageAll(params: any) {
    return request({
      url: "/project/projectAnyPhoto/pageAll",
      method: "get",
      params: params,
    });
  },
  // 分配处理人
  mobileanyPhotopageAllocate(params: any) {
    return request({
      url: "/project/projectAnyPhoto/pageAllocate",
      method: "get",
      params: params,
    });
  },
  // 我处理的
  mobileanyPhotopageDeal(params: any) {
    return request({
      url: "/project/projectAnyPhoto/pageDeal",
      method: "get",
      params: params,
    });
  },
  // 我审核的
  mobileanyPhotopageCheck(params: any) {
    return request({
      url: "/project/projectAnyPhoto/pageCheck",
      method: "get",
      params: params,
    });
  },
  // 我的提交列表
  mobileanyPhotopageCreate(params: any) {
    return request({
      url: "/project/projectAnyPhoto/pageCreate",
      method: "get",
      params: params,
    });
  },
  // 通知我的
  mobileanyPhotopageNotice(params: any) {
    return request({
      url: "/project/projectAnyPhoto/pageNotice",
      method: "get",
      params: params,
    });
  },
  // 处理问题
  mobileanyPhotoDealsaveDeal(data: any) {
    return request({
      url: "/project/projectAnyPhotoHistory/saveDeal",
      method: "post",
      data: data,
    });
  },
  // 审核问题
  mobileanyPhotoDealsaveCheck(data: any) {
    return request({
      url: "/project/projectAnyPhotoHistory/saveCheck",
      method: "post",
      data: data,
    });
  },

  // 关闭事件
  closeIgnore(data: any) {
    return request({
      url: "/project/projectAnyPhotoHistory/ignore",
      method: "post",
      data: data,
    });
  },
};
